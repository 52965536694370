* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f0f0f0;
}

header div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*font-size: 70px;*/
  font-weight: 600;
  background-color: #363636;
}


.container {
  padding: 40px 5%;
}

.heading-text {
  margin-bottom: 2rem;
  font-size: 2rem;
  text-align: center;
}

.heading-text span {
  font-weight: 100;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.image-gallery > li {
  flex-basis: 200px; /* width: 350px; */
  position: relative;
  cursor: pointer;
  list-style-type: none;
}

.image-gallery li figure {
  text-align: center;
  text-indent: 0;
  border: thin silver solid;
  /*border: 5px solid rgba(255, 255, 255, 0.2);*/
  margin: 0.5em;
  padding: 0.5em;
  background-color: #363636;
  border-radius:5%;
  overflow:hidden;
}

.image-gallery li figure img {
  object-fit: cover;
  max-width: 100%;
  /*height: auto;*/
  float: left;
  width:  200px;
  height: 200px;

  vertical-align: middle;
  border-radius: 5px;
}

.image-gallery li figure figcaption {
    color: #f6f6f6;
}

.image-gallery::after {
  content: "";
  flex-basis: 350px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.7);
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.2s 0.1s ease-in-out;
  color: #fff;
  border-radius: 5px;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hover */
.image-gallery li figure:hover .overlay {
  transform: scale(1);
}
